.contentContainerHeader {
  display: flex;
  height: 2rem;
  background-color: rgb(0, 154, 154);
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 20px;
  color: white;
  align-items: center;
  justify-content: center;
  max-width: unset;
}

.container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 5rem 2rem 5rem 2rem;
}

.cardContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;

  a {
    display: block;
    text-decoration: none;
    color: inherit;

    &:hover strong {
      color: #04bfbf;
    }
  }
}

@media (max-width: 768px) {
  .cardContainer {
    flex-direction: column;
    align-items: center;
  }
}