.containerGamefication {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  background-color: #f2637e;
  width: 100%;
}

.containerGamefication h1 {
  color: white;
  font-weight: 700;
  font-size: 2rem;
  text-align: left;
  margin-top: 1.5em;
}

.content {
  width: 90%;
  max-width: 1100px;
}

.subtitle {
  color: #04bfbf;
  font-weight: 700;
  font-size: 1.3rem;
  padding-top: 2rem;
}

.introduction {
  padding-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  max-width: 864px;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.table {
  margin-top: 2rem;
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prize .content .text {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.prize .content .text .subtext p {
  margin: 0 !important;
  padding: 0 !important;
}

.prize .content .text .thirdBoxText {
  margin-bottom: 2rem;
}

.rules {
  margin-bottom: 5rem;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg9wq8 {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}

.tg .tgBaqh {
  text-align: center;
  vertical-align: top;
}

.tg .tgC3ow {
  border-color: inherit;
  text-align: center;
  vertical-align: top;
}

.tg .tgUzvj {
  border-color: inherit;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.tg .tg7Btt {
  border-color: inherit;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
}

.tg .tg0Pky {
  border-color: inherit;
  text-align: left;
  vertical-align: top;
}

.tgUzvj,
.tg7Btt {
  background-color: #f3f3f3;
}