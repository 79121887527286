.footer {
  padding-top: 50px;
  width: 90%;
  max-width: 1100px;
  margin: auto;

  .container {
    display: flex;
    margin-top: 50px;

    div {
      width: 200px;

      ul {
        list-style: none;
        margin-top: 15px;
        padding-left: 0;
      }

      ul li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }

      a {
        color: gray;
      }

      h4 {
        color: rgb(4, 191, 191);
        font-size: 1.5rem;
      }
    }
  }

  p {
    color: grey;
  }

  .iconSocialMedia {
    margin-top: 10px;
  }

  @media (max-width: 1122px) {
    width: 90%;

    .container {
      margin: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

}