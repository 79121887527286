.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 5rem;
  html,
  body {
    background-color: rgb(239, 246, 245);
    overflow-x: hidden;
  }
}



.containerAboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  background-color: rgb(0, 154, 154);
  width: 100%;
}

.containerAboutUs h1 {
  color: white;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  margin-top: 1.5em;
}

.containerAboutUsProject,
.containerResults,
.containerMembers,
.containerMoments {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 90%;
  max-width: 1100px;
}

body .header {
  width: 100%;
  background-color: #04bfbf;
}

.containerAboutUsProject .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.containerAboutUsProject .content .text {
  max-width: 25rem;
}

.containerAboutUsProject .content .text h1,
.containerResults .content .text h1,
.containerMembers .content .text h1,
.containerMoments .content h1 {
  color: #04bfbf;
  font-weight: 700;
  font-size: 2.5rem;
  text-align: left;
  margin-bottom: 1.5rem;
}

.containerAboutUsProject .content .text p,
.containerResults .content .text p,
.containerMembers .content .text p {
  line-height: 1.875rem;
}

.containerAboutUsProject .content .image {
  margin-top: 2rem;
  max-width: 30rem;
  max-height: 25rem;
  min-width: 18rem;
}

.containerAboutUsProject .content .image img {
  width: 100%;
}

.containerPilares {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.conteudo {
  width: 90%;
  max-width: 1100px;
}

.texto {
  h2 {
    font-size: 2.5rem;
    color: #04bfbf;
  }

  p {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    color: #555;
  }
}

.pilaresTriplo {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.pilar {
  flex: 1;
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  .titulo {
    display: flex;
    align-items: center;

    img {
      margin-right: 1rem;
    }

    h1 {
      font-size: 2rem;
      color: #009a9a;
    }
  }

  p {
    margin-top: 1.5rem;
    font-size: 1.1rem;
    color: #555;
  }
}

@media (max-width: 768px) {
  .pilaresTriplo {
    flex-direction: column;
  }
}

.containerResults .content .cards {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.containerResults .content .cards .card {
  width: 10rem;
  border-radius: 0.625rem;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.875rem 1.875rem 1.875rem 3.687rem;
  @media (max-width: 768px) {
    height: 9.5rem;
  }
}

.containerResults .content .cards .card:nth-child(1) {
  background-color: #04BFBF;
  text-align: center;
}

.containerResults .content .cards .card:nth-child(2) {
  background-color: #FFBF28;
}

.containerResults .content .cards .card:nth-child(3) {
  background-color: #F2637E;
}

.containerResults .content .cards .card:nth-child(4) {
  background-color: #9747FF;
}

.containerResults .content .cards .card:nth-child(5) {
  background-color: rgb(11, 103, 198);
}

.containerResults .content .cards .card:nth-child(6) {
  background-color: rgb(160, 18, 93);
}

.containerResults .content .cards .card:nth-child(7) {
  background-color: rgb(45, 152, 246);
}

.containerResults .content .cards .card:nth-child(8) {
  background-color: rgb(0, 199, 63);
}

.containerMembers .cardsMembers {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  gap: .4rem;
  justify-content: space-around;
}

.containerMembers .cardsMembers .member {
  padding: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9rem;
  background-color: white;
  border-radius: .5rem;
}

.containerMembers .cardsMembers .member h2 {
  font-size: 1.5rem;
}

.containerMembers .cardsMembers .member .imageMember {
  max-width: 15rem;
  max-height: 15rem;
}

.containerMembers .cardsMembers .member img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.containerMembers .cardsMembers .member .socialMedia {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.containerMembers .cardsMembers .member .socialMedia a {
  max-width: 24px;
  max-height: 24px;
}

.containerMembers .cardsMembers .member .socialMedia img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.containerMoments .content .cardsMoments {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.containerMoments .content .cardsMoments .moment {
  display: flex;
  flex-direction: column;
  gap: .9rem;
  padding: .9rem;
  background-color: white;
  border-radius: .5rem;
  h1 {
    color: #009a9a;
    font-size: larger;
    font-weight: bold;
    margin-top: 0.5rem;
  }

  p {
    color: #696b6b;
    font-style: italic;
  }
}

.containerMoments .content .cardsMoments .moment .imageMoment {
  max-width: 19.25rem;
  max-height: 14.875rem;
  margin-bottom: 1.5rem;
}

.containerMoments .content .cardsMoments .moment .imageMoment img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
}

@media (max-width: 505px) {
  .containerAboutUsProject .content {
    flex-direction: column;
  }

  .containerResults .content .cards .card {
    width: 100%;
    text-align: left;
    padding-left: 0.5rem;
  }

  .containerMembers .content .cardsMembers,
  .containerMoments .content .cardsMoments {
    gap: 1.5rem;
  }

  .containerMembers .content .cardsMembers .member,
  .containerMoments .content .cardsMoments .moment {
    width: 100%;
  }

  .containerMembers .content .cardsMembers .member img {
    width: 90%;
  }

  .containerMoments .content .cardsMoments .moment img {
    width: 319px;
    height: 270px;
  }

  .containerMoments .content .cardsMoments .moment {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerMoments .content .cardsMoments .moment p {
    display: flex;
    justify-content: flex-start;
  }
}