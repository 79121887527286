.container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
  figure {
    text-align: center;
  }
}

.image {
  max-width: 10%;
  height: auto;
}

.post {
  text-align: left;
  max-width: 1120px;
  margin: 5rem auto 0;
  

  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    text-align: left;
  }

  time {
    display: block;
    font-size: 1rem;
    margin-top: 1.5rem;
    text-align: center;
  }

  .postContent {
    margin-top: 2rem;
    line-height: 2rem;
    font-size: 1.125rem;
  }
}

@media (max-width: 768px) {
  .post {
    h1 {
      font-size: 2rem;
      margin: auto;
    }
  }
  .postContent {
    text-align: justify;
  }
}