.containerLeitura {
  text-align: center;
  width: 90%;
  max-width: 1100px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.backgroundContainerLeitura {
  width: 100%;
  background-color: rgb(4, 191, 191);
  height: 500px;
}

.frameLecture {
  margin-top: -500px;
}

.frameLecture h2 {
  font-weight: bold;
}

.moreNews {
  width: 80%;
  margin: auto;
}

.moreNews h2 {
  padding-top: 40px;
  color: white;
  font-size: 2.8rem;
}

.moreNews button {
  margin-top: 20px;
  background: transparent;
  transition: 0.4s;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid white;
  cursor: pointer;
}

.moreNews button:hover {
  background-color: rgb(242, 99, 126);
  color: white;
}

.moreNews a {
  color: white;
  text-decoration: none;
}

.slide-content {
  margin: 40px 40px;
  overflow: hidden;
}

.cardNews {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  text-align: left;
}

.cardNews img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: 10px 10px 0 0;
}

.descriptionNews {
  display: flex;
  flex-direction: column;
  margin: 0px 10px 10px 10px;
  height: 260px;
  justify-content: space-between;
}

.descriptionNews h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.descriptionNews p {
  color: grey;
  font-size: 0.9rem;
  max-lines: 2;
}

.descriptionNews button {
  width: 100%;
  height: 40px;
  background-color: transparent;
  color: rgb(4, 191, 191);
  border-radius: 5px;
  border: 2px solid rgb(4, 191, 191);
  transition: 0.4s;
  cursor: pointer;
}

.descriptionNews button:hover {
  background-color: rgb(4, 191, 191, 0.2);
  color: white;
}

.swiper {
  padding: 50px !important;
}

.swiperSlide {
  background-color: rgb(4 47 46);
  color: #fff;
  font-size: 25px;
}

.swiper-navBtn {
  color: #6e93f7;
  transition: color 0.3s ease;
}

.swiper-navBtn:hover {
  color: #4070f4;
}

.swiper-navBtn::before,
.swiper-navBtn::after {
  font-size: 40px;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

@media (max-width: 1165px) {
  .backgroundContainerLeitura {
      width: 80%;
      max-width: 520px;
      margin: auto;
    }
}

@media (max-width: 640px) {
.swiper {
  padding: 30px 0px 30px 0px !important;
}
.swiper-button-next,
.swiper-button-prev {
  display: block;
}
.slideContent {
  width: 100%;
  margin: 25px 0;
  cursor: pointer;
}

}
