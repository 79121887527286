.title {
  color: rgb(4, 191, 191);
  text-align: center;
  font-size: 2.8rem;
  justify-content: center;
  display: flex;
  padding: 50px 0 0;

  div {
    color: red;
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding-top: 60px;
  }
}
.swiper {
  max-width: 800px;
  height: 400px;
}

.card {
  max-width: 1100px;
  height: 179px;
  padding: 16px;
  margin: 100px;
  border-radius: 10px;
  background-color: #fff;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
      margin: 50px;
      height: -webkit-fill-available;
    }
}

.cardContent {
  flex-grow: 1;
}

.cardText {
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: justify;
  color: grey;
}

.username {
  color: rgba(242, 99, 126, 1);
  font-size: 16px;
  font-weight: bold;
}