.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  html,
  body {
    background-color: rgb(239, 246, 245);
    overflow-x: hidden;
  }
}

.containerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  background-color: rgb(0, 154, 154);
  width: 100%;
}

.containerHeader h1 {
  color: white;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  margin-top: 1.5em;
}

.content {
  width: 90%;
  max-width: 1100px;
}

.container {
  max-width: 1120px;
  margin: 0 auto;
}

.cardContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;

  a {
    display: block;
    text-decoration: none;
    color: inherit;

    &:hover strong {
      color: #04bfbf;
    }
  }
}

@media (max-width: 768px) {
  .cardContainer {
    flex-direction: column;
    align-items: center;
  }
}