
.containerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  background-color: rgb(242, 99, 126);
  width: 100%;
}

.containerHeader h1 {
  color: white;
  font-weight: 700;
  font-size: 2.4rem;
  margin-top: 1.5em;
  max-width: 1120px;
}


@media (max-width: 768px) {
    .containerHeader {
      height: auto;
      h1 {
        width: 90%;
        text-align: left;
      }
    }
}