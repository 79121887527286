.moreContainer {
  max-width: 1040px;
  height: 450px;
  margin: 0px auto;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(4, 191, 191);
  padding: 5rem 2rem;

  h1 {
    text-align: center;
    font-size: 2.8rem;
    color: white;
  }
}

.backgroundContainerInitial {
  background-color: rgb(242, 99, 126);
  width: 100%;
}

.containerInitial {
  display: flex;
  align-items: center;
  padding-top: 130px;
  padding-bottom: 50px;
  width: 90%;
  max-width: 1100px;
  margin: auto;
  justify-content: space-between;
}

.containerInitial h2 {
  font-family: "montserrat";
  font-weight: 700;
  font-size: 45px;
  margin: auto;
  color: white;
}

.containerInitial img {
  width: 100%;
}

.headerContainer {
  height: 24rem;
  background-color: rgb(242, 99, 126);
  padding-top: 80px;
}

.contentContainer {
  max-width: 1110px;
  margin: 0px auto;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero {
  margin-top: 80px;
  max-width: 600px;

  h1 {
    font-size: 45px;
    line-height: 55px;
    font-weight: 700;
    margin-top: 2.5rem;
    color: white;
  }
}

.button {
  background: rgb(4, 191, 191);
  border: 2px solid white;
  font-size: 18px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  height: 45px
}

.circle {
  position: absolute;
  display: flex;
  background-color: #04bfbfb3;
  width: 5vw;
  height: 20vh;
  border-radius: 0px 150px 150px 0px;
  margin-top: 170px;
  margin-left: -20px;
}
@media (max-width: 605px) {
  .containerInitial h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1165px) {
  .headerContainer {
    padding-top: 0px;
    height: auto;
  }

  .containerInitial {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .contentContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .hero {
    margin-top: 50px;
    width: 100%;

    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-top: 0;
    }
  }

  .contentContainer img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
  }

  .circle {
    display: none;
  }
}