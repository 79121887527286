.card {
  margin: auto;
  background-color: white;
  border-radius: 10px;
  margin-top: 60px;
  display: flex;
  text-align: left;
  box-shadow: 5px 5px 30px gray;
  img {
    width: 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: auto;
    gap: 20px;
  
    p {
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    button {
      width: 100%;
      height: 50px;
      background-color: white;
      border: solid;
      border-color: rgb(4, 191, 191);
      border-radius: 5px;
      color: rgb(4, 191, 191);

      &:hover {
        background-color: rgba(4, 191, 191, 0.2);
      }
    }
  }
}


@media (max-width: 768px) {
  .card {
    flex-direction: column;
      width: 350px;
      margin-bottom: 20px;
    
      img {
        width: 100%;
        border-radius: 5px 5px 0px 0px;
      }
      .content {
        width: 90%;
      }
  }
    
}

