
.card {
  height: 460px;
  width: 350px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  box-shadow: 5px 5px 30px gray;
  overflow: hidden;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  &:hover {
    transform: scale(1.05);
  }
}

.card img {
  object-fit: cover;
  background-size: contain;
  width: 100%;
  height: 180px;
  border-radius: 10px 10px 0px 0px;
}

.image {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 16px 20px;
}

.description {
  color: grey;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin: 0 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

.button {
  background-color: rgb(4, 191, 191);
  color: white;
  border: 2px solid rgb(4, 191, 191);
  padding: 10px 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  margin: 20px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .card {
    width: 350px;
  }
}