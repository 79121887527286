body {
  .contentContainerHeader {
    display: flex;
    height: 9rem;
    background-color: rgb(0, 154, 154);
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 20px;
    color: white;
    align-items: center;
    justify-content: center;
    max-width: unset;
    h1 {
      padding: 20px;
    }
  }
  .container {
      max-width: 1120px;
      margin: 0 auto;
      padding: 5rem 2rem 5rem 2rem;
    h2 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      text-align: justify;
      color: #009a9a;
      font-size: 2rem;
      font-weight: bold;
    }

    h3 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      text-align: justify;
      color: #009a9a;
      font-size: 1.7rem;
      font-weight: bold;
    }
  }
}

.containerTitulo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  background-color: #009a9a;
  width: 100%;
  margin-bottom: 2rem;

  .texto {
    width: 90%;
    max-width: 1100px;
    margin: auto;
    padding-bottom: 1em;
  }

  h1 {
    color: white;
    font-weight: 700;
    font-size: 2.4rem;
    text-align: center;
    margin-top: 1.5em;
  }
}

.conteudo {
  width: 90%;
  max-width: 1100px;
  margin: auto;
  text-align: justify;
  margin-bottom: 3rem;
}

.containerMembros {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: initial;
}

.membro {
  width: 200px;
  margin: 0 15px 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  padding: 2% 1.3%;

  .membroConteudo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5%;
  }

  img {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  p {
    text-align: left;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.8rem;
  }

  .label {
    font-size: 1rem;
    margin: 0;
  }

  &.lider {
    width: 245px;

    p {
      text-align: center;
    }

    img {
      width: 120px;
      height: 120px;
    }
  }
}