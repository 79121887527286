
body .cabecalho {
  background-color: rgb(0, 154, 154);
}

.containerContato {
  background-color: rgb(0, 154, 154);
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerContato .conteudo {
  width: 90%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.containerContato .conteudo .contato h1 {
  color: white;
  font-weight: 700;
  font-size: 2.8rem;
  margin-bottom: 1.8125rem;
  text-align: left;
}

.containerContato .conteudo .contato {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  height: auto;
  align-items: left;
}

.containerContato .conteudo .contato a {
  text-decoration: none;
  color: white;
  margin-bottom: 0.9rem;
  display: flex;
  align-items: center;
}

.containerContato .conteudo .contato a img {
  margin-right: 0.625rem;
}

.containerContato .conteudo .contato a:nth-child(3) {
  margin-bottom: 1.81rem;
}

.containerContato .conteudo .contato .midiasSociais {
  display: flex;
  flex-direction: row;
}

.containerContato .conteudo .contato .midiasSociais a:nth-child(1) {
  margin-right: 1rem;
}

.formulario {
  display: flex;
  justify-content: center;
}

.formulario form {
  width: 420px;
  background-color: #ffffff;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.formulario form .button-form:hover {
  filter: brightness(120%);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input, textarea{
    outline: none;
    resize: none;
    padding: 0.5rem;
    background-color: #f7f7f7;
    border: none;
    border-radius: 5px;
    max-width: 30rem;
  }
  button {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    max-width: 400px;
  }

  textarea {
    resize: vertical;
    min-height: 150px;
  }

  button {
    background-color: #04BFBF;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #8ed1fc;
  }
}
@media (max-width: 915px) {

  .formulario form .input-form,
  .formulario form .textaria-form,
  .formulario form .button-form,
  .containerContato .conteudo .contato {
    max-width: 18rem;
  }
}

@media (max-width: 692px) {
  .containerContato .conteudo {
    justify-content: center;
    gap: 1.5rem;
  }

  .containerContato .conteudo .contato {
    justify-content: center;
    align-items: center;
  }

  .containerContato .conteudo .contato h1 {
    text-align: center;
    font-size: 2.5rem;
  }
    .formulario form {
      width: auto;
      input[type="text"],
        input[type="email"],
        textarea {
          width: auto;
          max-width: 400px;
        }
    }
}

@media (max-width: 300px) {

  .formulario form .input-form,
  .formulario form .textaria-form,
  .formulario form .button-form {
    max-width: 14rem;
  }
  .formulario form {
   width: auto;;
  }
}