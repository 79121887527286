header {
  max-width: 1100px;
  margin: auto;
  align-items: center;
  justify-content: center;
  font-family: "montserrat";
  font-weight: 600;
  font-size: 16px;
}

.cabecalho {
  padding-top: 20px;
  width: auto;
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.logoHeader {
  width: 120px;
  height: 80px;
}

.menuItem a:hover {
  border-bottom: 2px solid white;
}

.menuItem a:active {
  color: #f04060;
  border: 0px;
}

.navMenu {
  display: none;

  &.menuOpen {
    display: block;
  }

  .submenu {
    position: absolute;
    display: none;
    margin: 0 !important;
    padding: 1rem !important;
    border-radius: 0.5rem;
    background-color: white;
    list-style-type: none;
    
    li {
      position: relative;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 1rem;
      }

      &:hover {
        filter: brightness(50%);
      }

      a {
        color: rgb(242, 99, 126);
      }
    }
  }

  li:hover>ul {
    display: block;
  }
}

.linkGamificacao {
  color: white;
  cursor: pointer;
}

.iconeMenuMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
  padding-left: 60%;

  span {
    background-color: white;
    height: 4px;
    width: 100%;
    border-radius: 2px;
  }

  .closeIcon {
    font-size: 1.5rem;
    color: white;
  }
}

@media (max-width: 1166px) {
  .navMenu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100px;
    right: 0;
    width: 90%;
    background-color: white;
    padding: 1rem;
    list-style: none;
    border-radius: 1rem;

    &.menuOpen {
      display: flex;
    }

    li {
      margin-bottom: 1rem;
      
      a {
        color: black;
        font-weight: 500;
        text-decoration: none;
        border-left: 3px solid rgb(242, 99, 126);
        padding-left: .5rem;
      }
    }
  }

  .iconeMenuMobile {
    display: flex;
  }
}

@media (min-width: 1166px) {
  .navMenu {
    display: flex;
    flex-direction: row;
    gap: 50px;
    list-style: none;

    li a {
      color: white;
      font-size: 1rem;
      transition: 0.7s;
      padding: 15px;
      text-decoration: none;

      &:hover {
        background-color: transparent;
        border-radius: 0px;
        color: white;
      }
    }
  }

  .iconeMenuMobile {
    display: none;
  }
}